import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import instance from "../services/axiosConfig";
import axios from "axios";

const useScript = (src) => {
  useEffect(() => {
    setTimeout(() => {
      window.fbAsyncInit = function () {
        const FB = window.FB;
        FB.init({
          appId: "783513489930921",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v19.0",
        });
      };
    }, 500);
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 1200);
  }, [src]);
};

export default function WhatsappLoginPage() {
  useScript("https://connect.facebook.net/en_US/sdk.js");
  return (
    <Row>
      <Col>
        <Button
          onClick={() => launchWhatsAppSignup()}
          style={{
            backgroundColor: "#1877f2",
            border: 0,
            borderRadius: "4px",
            color: "#fff",
            cursor: "pointer",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "bold",
            height: "40px",
            padding: "0 24px",
          }}
        >
          Login with Facebook
        </Button>
      </Col>
    </Row>
  );
}

async function launchWhatsAppSignup() {
  const FB = window.FB;
  FB.login(
    function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        const payload = {
          accessToken,
          authResponse: response.authResponse,
        };
        instance
          .post(
            "/whatsapp/login/",
            { payload },
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    },
    {
      config_id: "810302434106561",
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {},
      },
    },
  );
}
