import React, { useEffect, useRef, useState } from "react";
import { FiCheck, FiSearch, FiTrash2, FiX } from "react-icons/fi";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import {
  deleteAddress,
  getAddresses,
  getAddressesByPage,
  searchAddresses,
  selectAddress,
  setAddressPageSize,
  setDefaultAddress,
} from "../../actions";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";
import { Spinner } from "react-bootstrap";

const AddressListRow = ({
  item,
  selectAddress,
  toggleList,
  setDefaultAddress,
  deleteAddress,
  isDefault,
  showAddressDeleteConfirmation,
  toggleAddressConfirmation,
  toggleDefaultLoading,
}) => {
  const {
    id,
    name,
    company,
    street1,
    street2,
    city,
    state,
    zip_code,
    country,
    email,
    phone,
  } = item;
  return (
    <div
      key={id}
      className={
        "address-list-item-container d-flex flex-row justify-content-between align-items-center"
      }
      onClick={() => {
        selectAddress(item, state.listType, () => toggleList(state.listType));
      }}
    >
      <div className="address-list-item d-flex flex-column" key={id}>
        <h3>
          {`${name} `}
          {company ? `@ ${company}` : null}{" "}
          {isDefault ? <span className="default-tag">DEFAULT</span> : null}
        </h3>
        {/* <hr className="m-0 p-1"/> */}
        <p className="address">
          {`${street1}, ${street2 || ""}, ${city}, ${state} ${zip_code}, ${country}`}{" "}
        </p>
        {email && <p>{email}</p>}
        {phone && <p>{phone}</p>}
      </div>
      <div className={"address-list-item-actions"}>
        {!isDefault && (
          <a
            onClick={(e) => {
              e.stopPropagation();
              setDefaultAddress(item);
            }}
            aria-disabled={toggleDefaultLoading}
          >
            {id === toggleDefaultLoading ? (
              <Spinner className={"text-dark"} />
            ) : (
              "Set Default"
            )}
          </a>
        )}
        {showAddressDeleteConfirmation === id ? (
          <div className={"delete-address-confirmations"}>
            <a
              className={"confirm"}
              onClick={(e) => {
                e.stopPropagation();
                deleteAddress();
              }}
            >
              <FiCheck />
            </a>
            <a
              className={"deny"}
              onClick={(e) => {
                e.stopPropagation();
                toggleAddressConfirmation(null);
              }}
            >
              <FiX />
            </a>
          </div>
        ) : (
          <a
            className={"delete-address-item"}
            onClick={(e) => {
              e.stopPropagation();
              toggleAddressConfirmation(id);
            }}
          >
            <FiTrash2 />
          </a>
        )}
      </div>
    </div>
  );
};

const AddressListModal = (props) => {
  const [showConfirmation, toggleAddressConfirmation] = useState(null);
  const creationAddressList = useRef();

  useEffect(() => {
    props.getAddresses(props.addressPageSize, props.addressSearchText);
    if (props.showList && creationAddressList.current) {
      creationAddressList.current.getElementsByTagName("input")[0].focus();
    }
  }, [props.showList]);

  useEffect(() => {
    props.getAddresses(props.addressPageSize, props.addressSearchText);
  }, [props.addressSearchText, props.addressPageSize]);

  let list = null;
  if (props.getAddressLoad) {
    list = <div className="spinner-border text-dark m-auto"></div>;
  } else if (props.addressErrors) {
    list = (
      <MessageBlock type={"error"} message={parseError(props.addressErrors)} />
    );
  } else if (props.showList) {
    list = props.addressList.map((item) => (
      <AddressListRow
        key={item.id}
        item={item}
        selectAddress={() =>
          props.selectAddress(item, props.listType, () => {
            props.toggleList();
          })
        }
        setDefaultAddress={() =>
          props.setDefaultAddress(item, () =>
            props.getAddresses(props.addressPageSize, props.addressSearchText),
          )
        }
        toggleDefaultLoading={props.addressDefaultLoading}
        deleteAddress={() =>
          props.deleteAddress(item.id, () => {
            props.getAddresses(props.addressPageSize);
            toggleAddressConfirmation(null);
          })
        }
        toggleList={() => props.toggleList()}
        isDefault={item.is_default}
        showAddressDeleteConfirmation={showConfirmation}
        toggleAddressConfirmation={(value) => toggleAddressConfirmation(value)}
      />
    ));
  }
  return (
    props.showList && (
      <div
        ref={creationAddressList}
        className="modal col-12 p-0 d-flex justify-content-center justify-content-lg-start aligin-items-center"
      >
        <div className="backdrop" onClick={() => props.toggleList()}></div>

        <div className="modal-content address-list-modal settings-card d-flex col-11 col-lg-7 m-auto">
          <div className="list-head card-section head d-flex flex-row justify-content-between">
            <div className=" d-flex flex-row">
              <h3>Saved Addresses</h3>
              <p
                className="refresh-btn mb-0 mx-3"
                onClick={() => props.getAddresses(props.addressPageSize)}
              >
                Refresh
              </p>
            </div>
            <a onClick={() => props.toggleList()}>
              <FiX size={25} />
            </a>
          </div>
          <div className="list-content card-section ">
            <div className="search-action">
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  props.searchAddresses(e.target.value);
                }}
                value={props.addressSearchText}
              />
              <FiSearch size={25} className="search-icon" />
              <FiX
                size={25}
                className={`clear-icon ${props.addressSearchText.toString().trim() !== "" ? "active" : ""}`}
                onClick={() => {
                  props.searchAddresses("");
                }}
              />
            </div>
            {list}
          </div>
          <div className={"list-pagination card-section last"}>
            <Pagination
              current={props.addressCurrent}
              count={props.addressCount}
              pageSize={props.addressPageSize}
              getListByPage={(pageNo) =>
                props.getAddressesByPage(
                  null,
                  pageNo,
                  props.addressPageSize,
                  props.addressSearchText,
                )
              }
              setPageSize={(size) => props.setAddressPageSize(size)}
            />
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = ({ addressList, userDetails }) => ({
  addressList: addressList.addresses,
  getAddressLoad: addressList.loading,
  addressErrors: addressList.errors,
  addressSearchText: addressList.searchText,
  addressCurrent: addressList.currentPage,
  addressCount: addressList.addressResponse?.count,
  addressPageSize: addressList.pageSize,
  defaultAddress: userDetails.info?.default_address,
  addressDefaultLoading: addressList.defaultToggleLoading,
});

export default connect(mapStateToProps, {
  getAddressesByPage,
  setAddressPageSize,
  searchAddresses,
  getAddresses,
  selectAddress,
  setDefaultAddress,
  deleteAddress,
})(AddressListModal);
