import { functions } from "../../services/firebase";
import {
  FETCH_ADDRS,
  FETCH_ADDRS_ERROR,
  FETCH_ADDRS_SUCCESS,
  CHECK_ADDRESS,
  CHECK_ADDRESS_FAILED,
  CHECK_ADDRESS_SUCCESS,
  CLEAR_ADDR_FORM,
  CLEAR_RECEIVER,
  CLEAR_SENDER,
  CREATE_ADDR_UPDATE,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  EXCHANGE,
  EXCHANGE_ADDR_SELECT,
  EXCHANGE_ADDR_UPDATE,
  EXCHANGE_DEFAULT,
  EXCHANGE_DEFAULT_ADDR_SELECT,
  PHONEBOOK,
  RECEIVER,
  RECEIVER_ADDR_SELECT,
  RECEIVER_ADDR_UPDATE,
  SELECT_ADDR_TO_EDIT,
  SENDER,
  SENDER_ADDR_SELECT,
  SENDER_ADDR_UPDATE,
  SET_DEFAULT_ADDRESS,
  SEARCH_ADDRESS_TEXT_ENTERED,
  SET_ADDRESS_PAGE_SIZE,
  CHECKOUT,
  CHECKOUT_ADDR_SELECT,
  CHECKOUT_ADDR_UPDATE,
  CLEAR_CHECKOUT_ADDRESS,
  SET_DEFAULT_ADDRESS_SUCCESS,
  SET_DEFAULT_ADDRESS_FAILURE,
} from "../../constants/types";
import instance from "../../services/axiosConfig";
import { paginationHandler, parseError } from "./ActionHelpers";
import axios from "axios";
import { getAccountInfo } from "./UserActions";

const CancelToken = axios.CancelToken;
let cancel;

export const getAddresses =
  (pageSize = 25, searchText = "") =>
  (dispatch) => {
    dispatch({ type: FETCH_ADDRS });
    let searchURL = "";
    if (searchText.toString().trim()) {
      searchURL = "&search=" + searchText.toString().trim();
    }

    if (cancel !== undefined) {
      cancel();
    }

    instance
      .get(`/v1/addresses/?page_size=${pageSize}${searchURL}`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      })
      .then((res) => {
        dispatch({
          type: FETCH_ADDRS_SUCCESS,
          payload: paginationHandler(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: FETCH_ADDRS_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

export const getAddressesByPage =
  (onSuccess, pageNo, pageSize = 25, search = "") =>
  (dispatch) => {
    dispatch({ type: FETCH_ADDRS });
    let searchURL = "";
    if (search.toString().trim()) {
      searchURL = "&search=" + search.toString().trim();
    }

    instance
      .get(`/v1/addresses/?page_size=${pageSize}&page=${pageNo}${searchURL}`)
      .then((res) => {
        dispatch({
          type: FETCH_ADDRS_SUCCESS,
          payload: paginationHandler(res.data),
        });
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
        console.log(err);
        if (err.code === axios.AxiosError.ERR_CANCELED) {
          return;
        }
        const errMsg = parseError(err);
        dispatch({
          type: FETCH_ADDRS_ERROR,
          payload: { errors: errMsg },
        });
      });
  };

const getAddressesSuccess = (dispatch, { data }) => {
  if (data.success) {
    dispatch({
      type: FETCH_ADDRS_SUCCESS,
      payload: data.addresses,
    });
  } else {
    const errors =
      "errors" in data
        ? data.errors
        : "Having connection problems - try again shortly";
    dispatch({
      type: FETCH_ADDRS_ERROR,
      payload: { errors },
    });
  }
};

const getAddressesFailed = (dispatch, error) => {
  dispatch({
    type: FETCH_ADDRS_ERROR,
    payload: { errors: "Having connection problems - try again shortly" },
  });
  console.log(`this is the error for get_addresses : ${error}`);
};

export const createAddress = (address, type, onSuccess) => (dispatch) => {
  dispatch({ type: CHECK_ADDRESS });
  // create and check address
  const addressRequest = { ...address };
  if (
    addressRequest.phone &&
    !addressRequest.phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
  ) {
    dispatch({ type: CHECK_ADDRESS_FAILED, payload: "Invalid phone number" });
    return;
  }
  if (addressRequest.phone && addressRequest.phone.toString().length > 4) {
    addressRequest.phone = "+" + addressRequest.phone;
  } else {
    addressRequest.phone = "";
  }

  instance
    .post("/v1/addresses/", addressRequest)
    .then((response) => {
      addressCheckSuccess(dispatch, response.data, type, onSuccess);
    })
    .catch((err) => {
      addressCheckFailed(dispatch, err, type);
    });
};

const addressCheckSuccess = (dispatch, data, type, onSuccess) => {
  updateCorrespondingAddress(dispatch, data, type);
  if (data.is_default) {
    dispatch({
      type: SET_DEFAULT_ADDRESS_SUCCESS,
      payload: data,
    });
  }
  dispatch({ type: CHECK_ADDRESS_SUCCESS });
  if (onSuccess) onSuccess();
};

const updateCorrespondingAddress = (dispatch, address_fields, type) => {
  switch (type) {
    case SENDER:
      dispatch({ type: SENDER_ADDR_SELECT, payload: address_fields });
      return;
    case RECEIVER:
      dispatch({ type: RECEIVER_ADDR_SELECT, payload: address_fields });
      return;
    case EXCHANGE_DEFAULT:
      dispatch({ type: EXCHANGE_ADDR_SELECT, payload: address_fields });
      return;
    case PHONEBOOK:
      dispatch({ type: SELECT_ADDR_TO_EDIT, payload: address_fields });
      return;
    case CHECKOUT:
      dispatch({ type: CHECKOUT_ADDR_SELECT, payload: address_fields });
      return;
    default:
      return;
  }
};

const addressCheckFailed = (dispatch, error, type) => {
  const errMsg = error.response?.status === 400 ? error.response.data : error;
  dispatch({
    type: CHECK_ADDRESS_FAILED,
    payload: {
      errors: errMsg,
      warnings: "",
    },
  });
  console.log(`this is the error for ${type} : ${parseError(errMsg)}`);
};

export const senderAddressUpdate = (prop, value) => {
  return {
    type: SENDER_ADDR_UPDATE,
    payload: { prop, value },
  };
};

export const defaultExchangeAddressUpdate = (prop, value) => {
  return {
    type: EXCHANGE_ADDR_UPDATE,
    payload: { prop, value },
  };
};

export const clearDefaultAddress = () => {
  return {
    type: CLEAR_SENDER,
  };
};

export const clearSenderAddress = () => {
  return {
    type: CLEAR_SENDER,
  };
};

// Receiver Actions

export function receiverAddressUpdate(prop, value) {
  return {
    type: RECEIVER_ADDR_UPDATE,
    payload: { prop, value },
  };
}

// Checkout Actions

export function checkoutAddressUpdate(prop, value) {
  return {
    type: CHECKOUT_ADDR_UPDATE,
    payload: { prop, value },
  };
}

export const clearReceiverAddress = () => {
  return {
    type: CLEAR_RECEIVER,
  };
};

export const clearCheckoutAddress = () => {
  return {
    type: CLEAR_CHECKOUT_ADDRESS,
  };
};

// Phone book - Address Create Actions

export function phonebookAddressUpdate(prop, value) {
  return {
    type: CREATE_ADDR_UPDATE,
    payload: { prop, value },
  };
}

export const clearCreateAddress = () => {
  return {
    type: CLEAR_ADDR_FORM,
  };
};

export const selectAddress = (addressFields, type, onFinish) => (dispatch) => {
  switch (type) {
    case SENDER:
      dispatch({ type: SENDER_ADDR_SELECT, payload: addressFields });
      break;
    case RECEIVER:
      dispatch({ type: RECEIVER_ADDR_SELECT, payload: addressFields });
      break;
    case PHONEBOOK:
      dispatch({ type: SELECT_ADDR_TO_EDIT, payload: addressFields });
      break;
    case EXCHANGE:
      dispatch({ type: EXCHANGE_ADDR_SELECT, payload: addressFields });
      break;
    case CHECKOUT:
      dispatch({ type: CHECKOUT_ADDR_SELECT, payload: addressFields });
      break;
    case EXCHANGE_DEFAULT:
      dispatch({ type: EXCHANGE_DEFAULT_ADDR_SELECT, payload: addressFields });
      break;
    default:
      break;
  }
  if (onFinish) onFinish();
};

export const deleteAddress = (addressID, onSuccess) => (dispatch) => {
  dispatch({ type: DELETE_ADDRESS });
  console.log(`Id for address to be deleted ${addressID}`);
  instance
    .delete(`/v1/addresses/${addressID}`)
    .then((response) => {
      deleteAddressSuccess(dispatch, addressID, onSuccess);
    })
    .catch((httpsError) => {
      console.log(`There problem with deleteAddress: ${httpsError}`);
      const errMsg = parseError(httpsError);
      dispatch({
        type: CLEAR_ADDR_FORM,
        payload: errMsg,
      });
    });
};

const deleteAddressSuccess = (dispatch, addressID, onSuccess) => {
  dispatch({ type: DELETE_ADDRESS_SUCCESS, payload: addressID });
  if (onSuccess) {
    onSuccess();
  }
};

export const setDefaultAddress = (address, onSuccess) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_ADDRESS,
    payload: address.id,
  });
  const requestData = {
    address: {
      id: address.id,
    },
  };
  instance
    .post("/v1/address/default/", requestData)
    .then((res) => {
      dispatch({
        type: SET_DEFAULT_ADDRESS_SUCCESS,
        payload: res.data.address,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: SET_DEFAULT_ADDRESS_FAILURE,
        payload: parseError(err),
      });
    });
};

export const searchAddresses = (searchPhrase) => (dispatch) => {
  dispatch({
    type: SEARCH_ADDRESS_TEXT_ENTERED,
    payload: searchPhrase,
  });
};

export const setAddressPageSize = (size) => (dispatch) => {
  dispatch({
    type: SET_ADDRESS_PAGE_SIZE,
    payload: size,
  });
};
