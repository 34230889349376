import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  FiArrowDown,
  FiArrowUp,
  FiCheck,
  FiChevronDown,
  FiChevronUp,
  FiCopy,
  FiDownload,
  FiMoreVertical,
  FiPlus,
  FiPrinter,
  FiSend,
  FiX,
} from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import "../../../scss/orders.scss";
import { connect } from "react-redux";
import { getOrders, selectOrder } from "../../actions";
import { Spinner } from "react-bootstrap";
import history from "../../../History";

import shopify from "../../../assets/shopify-logo-icon.svg";
import localStoreIcon from "../../../assets/store-icon.svg";
import woo_commerce from "../../../assets/woo-commerce-logo-icon.svg";
import etsy from "../../../assets/etsy-logo.svg";
import {
  CREDIT_CARD,
  ETSY,
  MPESA,
  SHOPIFY,
  WOOCOMMERCE,
} from "../../../constants/types";
import getCountryFlag from "country-flag-icons/unicode";
import { useCookies } from "react-cookie";
import { setApplicationContext } from "../../actions/ApplicationContextActions";
import TRACKING_STATUS from "../../../assets/tracking-status.json";
import toast from "react-hot-toast";
import { getQuantity, toMoneyFormat } from "../common/helpers";
import StatusItem from "../common/StatusItem";
import Popup from "../common/Popup";
import MPESALOGO from "../../../assets/mpesa-logo.svg";
import VISALOGO from "../../../assets/visa_card.svg";

const OrdersList = (props) => {
  const [cookies] = useCookies();

  const { pathname } = useLocation();
  const [copied, setCopied] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAction, setShowAction] = useState(false);
  const [actionElement, setActionElement] = useState(null);
  const [actionItem, setActionItem] = useState(null);

  useEffect(() => {
    if (props.selectedOrder) props.selectOrder(null);
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, [1000]);
    }
  }, [copied]);

  const getFulfillments = (orderFulfillments) => {
    if (!Array.isArray(orderFulfillments)) return null;
    const fulfillments = orderFulfillments?.filter((fulfillment) => {
      return (
        !!fulfillment?.rate_purchase?.labels.length &&
        fulfillment?.rate_purchase?.labels.some((label) => !!label.label_url)
      );
    });
    if (!fulfillments || fulfillments.length === 0) return null;
    const lastFulfillment = fulfillments[fulfillments.length - 1];
    return lastFulfillment.rate_purchase?.labels.find(
      (label) => !!label.label_url,
    );
  };

  const getTracking = (orderFulfillments) => {
    if (!Array.isArray(orderFulfillments)) return null;
    const fulfillments = orderFulfillments?.filter((fulfillment) => {
      return (
        !!fulfillment?.rate_purchase?.labels.length &&
        fulfillment?.rate_purchase?.labels.some(
          (label) => !!label.tracking_url_provider,
        )
      );
    });
    if (!fulfillments || fulfillments.length === 0) return null;
    const lastFulfillment = fulfillments[fulfillments.length - 1];
    return lastFulfillment.rate_purchase?.labels.find(
      (label) => !!label.tracking_url_provider,
    );
  };

  const getImages = (item) => {
    if (!item) return null;

    if (item.variation?.images?.length > 0) {
      return item.variation?.images[0].image;
    }
    if (item.variation?.product_images?.length > 0) {
      return item.variation?.product_images[0].image;
    }
    if (!!item.image) {
      return item.image;
    }
    return null;
  };

  const getTitle = (item) => {
    if (!item) return "Item";
    if (!!item.variation?.title) return `${item.variation.title}`;
    else if (!!item?.name) return `${item.name}`;
    else if (!!item?.external_product_id) return `${item.external_product_id}`;
    else return "Item";
  };

  const toggleActionMenu = (item, element) => {
    setActionItem((prevItem) => {
      if (prevItem && prevItem.external_id === item.external_id) {
        setActionElement(null);
        setShowAction(false);
        return null;
      }

      setActionElement(element);
      setShowAction(true);
      return item;
    });
  };

  const dateFormatter = (cell) => {
    const key = "date-column-value";
    return (
      <p key={key}>
        {!cell
          ? ""
          : moment(new Date(cell)).format("MMM D, YYYY [\r\n] hh:MM A")}
      </p>
    );
  };

  const textFormatter = (cell) => {
    const key = "text-column-value";
    return <p key={key}>{cell}</p>;
  };

  const customerFormatter = (cell) => {
    const key = "customer-column-value";
    if (cell)
      return (
        <div
          key={key}
          className={
            "d-flex flex-column justify-content-start align-items-start"
          }
        >
          <p
            className={"customer-name"}
          >{`${cell.country ? getCountryFlag(cell.country) : ""} ${cell.name}`}</p>
          <p className={"customer-address"}>
            <span className={"mr-3"}>{`${cell.country || ""}`}</span>
            {`${cell.city} , ${cell.zip_code}`}
          </p>
        </div>
      );
    return <div key={key}></div>;
  };
  const storeFormatter = (cell) => {
    const key = "store-column-value";
    let text = "";

    switch (cell.platform.toString().toUpperCase()) {
      case SHOPIFY:
        return (
          <div key={key} className={"d-inline"}>
            <img className={"d-none d-xl-flex"} src={shopify} alt={"shopify"} />
            <h5 className={"d-flex d-xl-none"}>
              {cell.platform.toString().toUpperCase()}
            </h5>
          </div>
        );
      case WOOCOMMERCE:
        return (
          <div key={key} className={"d-inline"}>
            <img
              className={"d-none d-xl-flex"}
              src={woo_commerce}
              alt={"woo_commerce"}
            />
            <h5 className={"d-flex d-xl-none"}>
              {cell.platform.toString().toUpperCase()}
            </h5>
          </div>
        );
      case ETSY:
        return (
          <div key={key} className={"d-inline"}>
            <img className={"d-none d-xl-flex"} src={etsy} alt={"etsy"} />
            <h5 className={"d-flex d-xl-none"}>
              {cell.platform.toString().toUpperCase()}
            </h5>
          </div>
        );
      default:
        return (
          <div
            key={key}
            className={
              "d-inline-flex flex-row justify-content-start align-items-center"
            }
          >
            <img
              className={"d-none d-xl-flex"}
              src={cell.shop_logo ? cell.shop_logo : localStoreIcon}
              alt={cell.shop_name}
            />
            <h5 className={"d-flex"}>{cell.shop_name}</h5>
          </div>
        );
    }
  };

  const trackingFormatter = (cell, row) => {
    const key = "tracking-column-value";
    const fulfillment = cell.find(
      (item) => !!item.rate_purchase?.labels?.length,
    );
    const label = fulfillment?.rate_purchase.labels[0] || null;

    const eventType = label?.tracking_status?.event_type;
    const eventTypes = TRACKING_STATUS;
    const className = label
      ? eventType && Object.keys(eventTypes).includes(eventType)
        ? eventType
        : "pre-transit"
      : "no-label";
    const text =
      eventTypes[eventType] || (label ? "Pre Transit" : "Unfulfilled");

    return text;
  };

  const itemsFormatter = (cell, row) => {
    return (
      <p
        className={"items-count"}
      >{`${cell.length} item${cell.length > 1 ? "s" : ""}`}</p>
    );
  };

  const orderFormatter = (cell, row) => {
    const key = "order-column-value";

    switch (cell?.toString().toLowerCase()) {
      case "fulfilled":
      case "fulfilled_externally":
        return (
          <StatusItem
            key={`${key}-${row.id}`}
            message={"Completed"}
            className={`bordered circle success`}
          />
        );
      case "awaiting_payment":
        return (
          <StatusItem
            key={`${key}-${row.id}`}
            message={"Payment Required"}
            className={`bordered circle error`}
          />
        );
      case "open":
        return (
          <StatusItem
            key={`${key}-${row.id}`}
            message={"Open"}
            className={`bordered circle warning`}
          />
        );
      case "cancelled":
        return (
          <StatusItem
            key={`${key}-${row.id}`}
            message={"Cancelled"}
            className={`bordered circle`}
          />
        );
      default:
        if (row.label_status?.length > 0) {
          return (
            <StatusItem
              key={`${key}-${row.id}`}
              message={"Completed"}
              className={`bordered circle success`}
            />
          );
        }
        return (
          <StatusItem
            key={`${key}-${row.id}`}
            message={"Open"}
            className={`bordered circle warning`}
          />
        );
    }
  };

  const labelFormatter = (cell, row) => {
    const key = "label-column-value" + row.id;

    if (row.state?.toString().toLowerCase() === "fulfilled_externally") {
      return (
        <StatusItem
          key={key}
          message={"Fulfilled Externally"}
          className={"background success"}
        />
      );
    }

    const fulfillment = cell.find(
      (item) => !!item.rate_purchase?.labels?.length,
    );

    const labels = fulfillment?.rate_purchase?.labels || null;

    if (!!labels?.length) {
      const labelsWithURL = labels.filter((label) => label.label_url);
      if (labelsWithURL.length > 0)
        return (
          <StatusItem
            key={key}
            className={"background warning"}
            message={`Fulfilled - ${trackingFormatter(row.tracking, row)}`}
          />
        );
      return (
        <StatusItem
          key={key}
          className={"background error"}
          message={`Fulfilled - Failed`}
        />
      );
    } else {
      return (
        <StatusItem
          key={key}
          className={"background error"}
          message={`Unfulfilled`}
        />
      );
    }
  };

  const paymentFormatter = (cell, row) => {
    const key = "payment-column-value-" + row?.id;
    if (row.state?.toString().toLowerCase() === "awaiting_payment") {
      return (
        <StatusItem
          key={key}
          message={"Pending Payment"}
          className={"circle error"}
        />
      );
    }
    if (!!cell && parseFloat(cell) === parseFloat(row?.price)) {
      return (
        <StatusItem key={key} message={"Paid"} className={"circle success"} />
      );
    }
    if ((!cell || parseFloat(cell) === 0) && !!row.amount_to_be_collected) {
      return (
        <StatusItem key={key} message={"Not Paid"} className={"circle error"} />
      );
    }
    if (!cell || parseFloat(cell) < parseFloat(row?.price)) {
      return (
        <StatusItem
          key={key}
          message={"Partially Paid"}
          className={"circle warning"}
        />
      );
    }
    return <StatusItem key={key} message={"Unknown"} className={"circle"} />;
  };

  const actionFormatter = (cell, row) => {
    const key = "actions-column-value-" + row.id;
    const fulfillments = cell?.filter((fulfillment) => {
      return (
        !!fulfillment?.rate_purchase?.labels.length &&
        fulfillment?.rate_purchase?.labels.some((label) => !!label.label_url)
      );
    });
    const lastFulfillment =
      !!fulfillments && fulfillments.length > 0
        ? fulfillments[fulfillments.length - 1]
        : null;
    const labels = !!lastFulfillment
      ? lastFulfillment.rate_purchase?.labels
      : null;
    const label = !!labels && labels.length === 1 ? labels[0] : null;

    return (
      <div
        key={key}
        className={`table-actions d-flex flex-row align-items-center`}
      >
        {!!label ? (
          <a
            href={label.label_url}
            target={"_blank"}
            onClick={() => {
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            }}
          >
            <FiDownload />
          </a>
        ) : (
          <div></div>
        )}
        <FiMoreVertical
          size={35}
          onClick={(e) => {
            const order =
              props.list.find((item) => item.external_id === row.id) || null;
            toggleActionMenu(order, e.target);
            e.stopPropagation();
          }}
          className={"d-xl-inline"}
        />
      </div>
    );
  };

  const getPaymentMethod = (method) => {
    switch (method) {
      case MPESA:
        return <img src={MPESALOGO} alt={"mpesa"} />;
      case CREDIT_CARD:
        return <img src={VISALOGO} alt={"mpesa"} />;
      default:
        return <p>N/A</p>;
    }
  };

  const checkFeatures = (item) => {
    if (!item.variation) return null;
    if (!item.variation.features?.length) return null;
    const features = item.variation.features.reduce((featureText, feature) => {
      if (feature.slug === "default") return featureText + "";
      if (!!featureText.toString().trim()) featureText += " / ";
      return featureText + feature.user_visible_value.toString().trim();
    }, "");
    return !!features ? features : null;
  };

  const expandedRow = {
    renderer: (row) => {
      if (row.items?.length > 0) {
        const fulfillment = row.label_status.find(
          (item) => !!item.rate_purchase?.labels?.length,
        );

        const labels = fulfillment?.rate_purchase?.labels || null;

        const list = row.items.map((item, idx) => {
          const { title, sku, price, images } = item.variation || {};
          const label_url =
            labels?.length > idx && labels[idx]?.label_url
              ? labels[idx]?.label_url
              : "";

          const features = checkFeatures(item);
          return (
            <div
              key={item.id}
              className={
                "expanded-row-details-item d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div
                className={"flex-row justify-content-start align-items-center"}
              >
                {getImages(item) ? (
                  <img
                    src={getImages(item)}
                    alt={`product image ${item.external_product_id}`}
                  />
                ) : (
                  <div></div>
                )}
                <p>{`${getQuantity(item)}`}</p>
                <p>{`${getTitle(item)}`}</p>
                {!!item.metadata?.length && (
                  <a
                    className={"action"}
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                  >
                    Show Details
                  </a>
                )}
                {!!features ? <p>{`${features}`}</p> : null}
              </div>
              {labels && labels.length === 1 ? (
                <a
                  rel="noreferrer"
                  className={`download-icon`}
                  href={label_url}
                  download
                  target={"_blank"}
                >
                  Download Label
                </a>
              ) : (
                <div></div>
              )}
              {labels && labels?.length > 1 && idx === 0 ? (
                <a
                  rel="noreferrer"
                  className={`download-icon`}
                  onClick={() => {
                    const label = labels[labels.length - 1];
                    props.goToLabels(
                      label.tracking_number ? label.tracking_number : label.id,
                    );
                  }}
                >
                  Go to Labels
                </a>
              ) : null}
            </div>
          );
        });

        return (
          <div className={"expanded-row-details"}>
            <div className={"expanded-row-payment-details"}>
              <div className={"payment-detail-item"}>
                <p className={"info"}>Payment Status</p>
                {paymentFormatter(row.total_paid, row)}
              </div>
              <div className={"payment-detail-item"}>
                <p className={"info"}>Amount Paid</p>
                {toMoneyFormat(row?.total_paid || 0, row.currency || "")}
              </div>
              {!!row.amount_to_be_collected ||
              row.state === "awaiting_payment" ? (
                <div className={"payment-detail-item"}>
                  <p className={"info"}>Amount Remaining</p>
                  {toMoneyFormat(
                    !!row.amount_to_be_collected
                      ? row.amount_to_be_collected
                      : row.items?.reduce((acc, item) => {
                          return acc + parseFloat(item.price);
                        }, 0),
                    row.currency || "",
                  )}
                </div>
              ) : null}
              <div className={"payment-detail-item"}>
                <p className={"info"}>Payment Method</p>
                {getPaymentMethod(row.payment_method)}
              </div>
              <div className={"payment-detail-item"}>
                <p className={"info"}>Payment Date</p>
                {row.payment_date ? (
                  <p>{`${moment(new Date(row.payment_date)).format("MMM D, YYYY")}`}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
            <div className={"expanded-row-items"}>
              <div
                className={
                  "expanded-row-details-item header d-flex flex-row justify-content-between align-items-center"
                }
              >
                <div
                  className={
                    "flex-row justify-content-start align-items-center"
                  }
                >
                  <p>Image</p>
                  <p>Qty</p>
                  <p>Item</p>
                  {row?.items.some((item) => !!checkFeatures(item)) && (
                    <p>Features</p>
                  )}
                </div>
              </div>
              {list.reverse()}
            </div>
          </div>
        );
      } else return <div key={"expanded-id"}></div>;
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandColumnPosition: "left",
    onlyOneExpanding: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <FiChevronUp />;
      }
      return <FiChevronDown />;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <a>
            <FiChevronUp />
          </a>
        );
      }
      return (
        <a>
          <FiChevronDown />
        </a>
      );
    },
    className: "table-expanded-row",
    parentClassName: "table-expanded-row-container",
  };

  const columns = [
    // { dataField: "checked", text: <input type="checkbox"/> ,formatter:checkboxFormatter},
    { dataField: "store", text: "Store", formatter: storeFormatter },
    { dataField: "order_no", text: "Order#", formatter: textFormatter },
    {
      dataField: "date",
      text: "Date",
      formatter: dateFormatter,
      sort: true,
      sortCaret: () => {
        return props.orderDesc ? <FiArrowUp /> : <FiArrowDown />;
      },
      sortFunc: () => {
        props.toggleOrder();
      },
    },
    { dataField: "customer", text: "Customer", formatter: customerFormatter },
    {
      dataField: "state",
      formatter: orderFormatter,
      text: "Order Status",
    },
    { dataField: "items", text: "No. of Items", formatter: itemsFormatter },
    {
      dataField: "total_paid",
      text: "Payment Status",
      formatter: paymentFormatter,
    },
    {
      dataField: "tracking",
      text: "Shipment Status",
      formatter: labelFormatter,
    },
    { dataField: "label_status", text: "Action", formatter: actionFormatter },
  ];

  const list = props.list
    ? props.list.map((item, id) => {
        return {
          id: item.external_id,
          order_no: item.order_number,
          date: item.created_at,
          customer: item.recipient_address,
          store: item.shop,
          tracking: item.fulfillments,
          items: item.line_items,
          label_status: item.fulfillments,
          state: item.state,
          currency: item.currency,
          total_paid: item.total_paid,
          price: item.price,
          amount_to_be_collected: item.amount_to_be_collected,
          payment_method: item.payment_method,
          payment_date: item.payment_date,
        };
      })
    : [];

  return (
    <div
      className={`orders-list ${cookies.is_test ? "test-mode" : "live-mode"}`}
    >
      {props.errors && <p className={"text-danger"}>{props.errors}</p>}
      {props.loading ? (
        <Spinner animation={"border"} variant={"dark"} />
      ) : (
        <ToolkitProvider keyField="id" data={list} columns={columns} search>
          {(toolkitProps) => {
            if (props.search) toolkitProps.searchProps.onSearch(props.search);

            if (props.list.length > 0) {
              return (
                <div className="d-flex flex-column m-0 p-0">
                  <BootstrapTable
                    keyField={"id"}
                    {...toolkitProps.baseProps}
                    expandRow={expandedRow}
                    classes={"d-none d-xl-table"}
                  />
                  <div className={"table-list d-flex d-xl-none flex-column"}>
                    {list.map((item) => {
                      const lists = columns.map((col) => {
                        return [
                          <p
                            className={"field-name"}
                            key={`${col.dataField}-column-title`}
                          >
                            {col.text}
                          </p>,
                          col.formatter(item[col.dataField], item),
                        ];
                      });

                      return (
                        <div key={item.id} className={"table-item"}>
                          <div className={"item-fields col-12 "}>{lists}</div>
                          {/*{expandedRow.renderer(item)}*/}
                        </div>
                      );
                    })}
                  </div>
                  {!props.recent && props.pagination}
                  {props.recent ? (
                    <div className="more-button d-flex align-items-center justify-content-center m-auto">
                      <Link to={ROUTES.HOME + ROUTES.ORDERS}>View All</Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            } else {
              return props.searchText && props.searchText !== "" ? (
                <div>No matching orders found for search</div>
              ) : (
                <div>
                  No orders have been found. Make sure you connect a store!{" "}
                </div>
              );
            }
          }}
        </ToolkitProvider>
      )}
      {!!selectedItem && (
        <div className="modal">
          <div className="backdrop" onClick={() => setSelectedItem(null)}></div>
          <div className="modal-content settings-card lineitem-details">
            <div
              className={
                "card-section head d-flex flex-row align-items-center justify-content-between"
              }
            >
              <h3>{`Order item detail`}</h3>
              <a className={"action"} onClick={() => setSelectedItem(null)}>
                <FiX />
              </a>
            </div>
            <div
              className={
                "card-section last d-flex flex-column flex-md-row align-items-start"
              }
            >
              {getImages(selectedItem) && (
                <div className={"lineitem-detail-image col-md-6"}>
                  <img
                    src={getImages(selectedItem)}
                    alt={`product image ${selectedItem.external_product_id}`}
                  />
                </div>
              )}
              <div className={"lineitem-detail-content col-md-6"}>
                <h3>{getTitle(selectedItem)}</h3>
                {!!selectedItem.variation?.sku && (
                  <div
                    className={
                      "lineitem-sku d-flex flex-row justify-content-start align-items-center"
                    }
                  >
                    <p>{selectedItem.variation?.sku}</p>
                    <a
                      onClick={() => {
                        navigator.clipboard
                          .writeText(selectedItem.variation?.sku)
                          .then(() => {
                            setCopied(true);
                            toast("Copied");
                          });
                      }}
                      onKeyDown={() => {
                        navigator.clipboard
                          .writeText(selectedItem.variation?.sku)
                          .then(() => {
                            setCopied(true);
                            toast("Copied");
                          });
                      }}
                    >
                      {copied ? <FiCheck className={"copied"} /> : <FiCopy />}
                    </a>
                  </div>
                )}
                {!!selectedItem.metadata?.length && (
                  <div className={"lineitem-metadata "}>
                    {selectedItem.metadata.map((item, idx) => {
                      return [
                        <p className={"info"} key={`${item.key}+${idx}-title`}>
                          {item.key}
                        </p>,
                        <p key={`${item.value}+${idx}-value`}>{item.value}</p>,
                      ];
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Popup
        show={showAction}
        element={actionElement}
        close={() => {
          setShowAction(false);
          setActionElement(null);
          setActionItem(null);
        }}
        top={(actionElement?.getBoundingClientRect().top || 0) + 30 + "px"}
        right={"155px"}
      >
        <p className={"info"}>Actions</p>
        {actionItem && actionItem?.fulfillments?.length > 0 ? (
          <a
            onClick={() => {
              const labelWithURL = getFulfillments(actionItem?.fulfillments);
              let label = labelWithURL || null;
              if (!label) {
                const fulfillment = actionItem.fulfillments.find((item) => {
                  return item.rate_purchase?.labels?.length > 0;
                });
                if (!!fulfillment) {
                  const labels = fulfillment.rate_purchase.labels;
                  label = labels[labels.length - 1];
                }
              }

              const search =
                label?.tracking_number !== ""
                  ? label.tracking_number
                  : label.id;
              props.goToLabels(search);
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            }}
          >
            <Popup.Item
              className={
                "table-actions-item d-flex flex-row align-items-center justify-content-start"
              }
            >
              <FiPrinter />
              <p>Go to Labels</p>
            </Popup.Item>
          </a>
        ) : null}
        {actionItem &&
        getFulfillments(actionItem?.fulfillments)?.tracking_url_provider ? (
          <a
            href={
              getFulfillments(actionItem?.fulfillments).tracking_url_provider
            }
            target={"_blank"}
            onClick={() => {
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            }}
          >
            <Popup.Item
              className={
                "table-actions-item d-flex flex-row align-items-center justify-content-start"
              }
            >
              <FiSend />
              <p>Track shipment</p>
            </Popup.Item>
          </a>
        ) : null}
        <Popup.Item
          onClick={(e) => {
            props.selectOrder(actionItem, () => {
              props.setApplicationContext({ returnURL: pathname }, () => {
                history.push(ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE);
              });
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            });
          }}
          className={
            "table-actions-item d-flex flex-row align-items-center justify-content-start"
          }
        >
          <FiPlus />
          <p>Create a new label</p>
        </Popup.Item>
        {actionItem?.state === "awaiting_payment" &&
        actionItem.payment_request_url ? (
          <Popup.Item
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard
                .writeText(actionItem.payment_request_url)
                .then(() => {
                  setCopied(true);
                  toast("Copied");
                  setActionElement(null);
                  setActionItem(null);
                  setShowAction(false);
                });
            }}
            className={
              "table-actions-item d-flex flex-row align-items-center justify-content-start"
            }
          >
            <FiCopy />
            <p>Copy payment link</p>
          </Popup.Item>
        ) : null}
        {!!actionItem &&
        !!getFulfillments(actionItem?.fulfillments)?.commercial_invoice_url ? (
          <a
            href={
              getFulfillments(actionItem?.fulfillments)?.commercial_invoice_url
            }
            target={"_blank"}
            onClick={() => {
              setActionElement(null);
              setActionItem(null);
              setShowAction(false);
            }}
          >
            <Popup.Item
              className={
                "table-actions-item d-flex flex-row align-items-center justify-content-start"
              }
            >
              <FiDownload />
              <p>Download commercial invoice</p>
            </Popup.Item>
          </a>
        ) : null}
      </Popup>
    </div>
  );
};

const mapStateToProps = ({ stores }) => ({
  loading: stores.loading,
  errors: stores.errors,
  selectedOrder: stores.selectedOrder,
  pageSize: stores.pageSize,
});

export default connect(mapStateToProps, {
  getOrders,
  selectOrder,
  setApplicationContext,
})(OrdersList);
