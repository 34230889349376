import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import {
  CUSTOM_PACKAGE_I,
  FLAT_RATE_PACKAGE_I,
  RECEIVER,
  SENDER,
} from "../../../constants/types";
import AddressListItem from "../Address/AddressListItem";
import {
  selectAddress,
  clearSenderAddress,
  clearReceiverAddress,
  updateExtras,
  getRates,
  getAddresses,
  selectRate,
  clearCustoms,
  clearRate,
  clearRates,
  createTemplate,
  updateNewTemplate,
  updateTemplateName,
  selectTemplate,
  clearNewTemplate,
  clearSelectedTemplate,
  createCustomsDeclaration,
  editCustomsItems,
  selectOrder,
  searchAddresses,
  getAddressesByPage,
  setAddressPageSize,
  deleteAddress,
  setDefaultAddress,
  clearLabelCreationErrors,
  addParcelItem,
} from "../../actions";
import AddressForm from "../Address/AddressForm";
import "../../../scss/label.scss";
import PackageDetails from "./PackageDetails";
import RateSelection from "./RateSelection";
import CustomsInfo from "./CustomsInfo";
import CustomsItemForm from "./CustomsItemForm";
import PurchaseLabel from "./PurchaseLabel";
import LabelTemplate from "./LabelTemplate";
import {
  FiX,
  FiArrowLeft,
  FiSearch,
  FiTrash2,
  FiCheck,
  FiAlertCircle,
} from "react-icons/fi";
import { withRouter } from "react-router-dom";

import localParcels from "../../../assets/usps-flatrate-parcels-topchoices.json";
import { compose } from "recompose";
import { withCookies } from "react-cookie";
import TemplateList from "./TemplateList";

import { ReactComponent as Check } from "../../../assets/check.svg";
import { ReactComponent as TemplateListIcon } from "../../../assets/template-prompt-icon.svg";
import {
  getUserCountry,
  setApplicationContext,
} from "../../actions/ApplicationContextActions";
import Pagination from "../common/Pagination";
import { missingDetails, validatePackage } from "../common/helpers";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import MessageBlock from "../common/MessageBlock";
import { parseError } from "../../actions/ActionHelpers";
import { Spinner } from "react-bootstrap";

class CreateLabel extends PureComponent {
  state = {
    showForm: false,
    showList: false,
    listType: SENDER,
    formType: SENDER,
    receiverError: "",
    senderError: "",
    fieldsError: { valid: true },
    parcelsError: [],
    customsError: { valid: true },
    loadedTemplate: "",
    templateError: "",
    showRates: false,
    showCustoms: false,
    showCustomsForm: false,
    showPurchase: false,
    createTemplate: false,
    showTemplateList: false,
    showSaveTemplatePrompt: false,
    askSaveTemplate: true,
    showAddressDeleteConfirmation: null,
    showVerificationPrompt: false,
  };

  constructor() {
    super();
    this.templateFormRef = createRef();
    this.creationAddressList = createRef();
  }

  componentDidMount = () => {
    this.props.clearRate();
    this.props.clearRates();
    this.props.clearLabelCreationErrors();
    this.props.getUserCountry();
    if (this.props.sender.country !== this.props.receiver.country) {
      this.setState({ showCustoms: true });
    }

    if (
      !this.props.sender ||
      (!this.props.sender.external_id && !this.props.sender.id) ||
      (this.props.sender.external_id === "" && this.props.sender.id === "")
    ) {
      this.props.clearSenderAddress();
    }

    if (
      !this.props.receiver ||
      (!this.props.receiver.external_id && !this.props.receiver.id) ||
      (this.props.sender.external_id === "" && this.props.receiver.id === "")
    ) {
      // this.props.clearReceiverAddress()
    }

    if (
      this.props.defaultAddress &&
      this.props.defaultAddress.id !== "" &&
      (!this.props.sender ||
        !this.props.sender.id ||
        this.props.sender.id === "")
    ) {
      this.props.selectAddress(this.props.defaultAddress, SENDER, () => {
        return;
      });
    }

    if (!this.props.customs.items.every((item) => item.origin_country)) {
      this.props.customs.items.forEach((item, idx) => {
        if (!item.origin_country) {
          this.props.editCustomsItems(
            {
              ...item,
              origin_country:
                this.props.defaultAddress?.country ||
                this.props.currentCountry ||
                "US",
            },
            idx,
          );
        }
      });
    }

    if (
      this.props.sender?.id &&
      !this.props.customs.items.every((item) => item.origin_country)
    ) {
      this.props.customs.items.forEach((item, idx) => {
        if (!item.origin_country) {
          this.props.editCustomsItems(
            {
              ...item,
              origin_country:
                this.props.sender?.country || this.props.currentCountry || "US",
            },
            idx,
          );
        }
      });
    }

    if (this.props.labelTemplate.selectedTemplate) {
      this.props.selectTemplate(this.props.labelTemplate.selectedTemplate);
    }
  };

  validateParcelList = async () => {
    let valid = true;
    if (
      this.props.sender?.country === "KE" &&
      this.props.receiver?.country === "KE"
    ) {
      if (
        this.props.parcels?.parcels?.length === 0 &&
        this.props.selectedParcel?.quantity === 1
      ) {
        if (
          ["length", "width", "height"].every(
            (item) => !this.props.selectedParcel[item],
          )
        ) {
          if (this.props.selectedWeight.weight > 0) {
            await this.props.addParcelItem({ ...this.props.selectedWeight });
            return valid;
          } else {
            this.setState({
              fieldsError: {
                valid: false,
                errors: {
                  weight: true,
                },
                message: "Please enter a valid weight value",
              },
            });
            return false;
          }
        }
      }
    }
    const checkPackageFields = validatePackage(
      this.props.selectedParcel,
      this.props.selectedWeight,
      this.props.packageType,
    );

    if (this.props.parcels?.parcels?.length === 0) {
      valid = checkPackageFields.valid;
      if (valid) {
        await this.props.addParcelItem({
          ...this.props.selectedParcel,
          ...this.props.selectedWeight,
        });
      } else {
        this.setState({ fieldsError: { ...checkPackageFields } });
      }
    }

    return valid;
  };

  validateCustoms = () => {
    const customsError = {
      valid: true,
    };
    if (this.props.customs.items.length < 1) {
      customsError.items = true;
      customsError.valid = false;
    }
    if (!this.props.customs.certified) {
      customsError.certified = true;
      customsError.valid = false;
    }
    if (!this.props.customs.customsSigner.trim()) {
      customsError.signer = true;
      customsError.valid = false;
    }
    return customsError;
  };

  validateRateChecks = async () => {
    //clear errors before checking again
    this.setState({ receiverError: "", senderError: "" });
    //TODO: Validate Addresses
    // - validate that all required fields are there in both to and from
    let valid = true;
    valid = this.validateAddress(this.props.sender, SENDER) && valid;
    valid = this.validateAddress(this.props.receiver, RECEIVER) && valid;
    valid = (await this.validateParcelList()) && valid;
    // - validate that if international, to is US, and from has a phone # / email
    if (valid && this.props.sender.country !== this.props.receiver.country) {
      if (!this.props.businessDetails.is_verified) {
        this.setState({
          showVerificationPrompt: true,
        });
        return false;
      }
      if (!this.props.receiver.phone) {
        this.setErrorMessage(
          RECEIVER,
          "phone number is required for international shipments",
        );
        valid = false;
      } else if (this.props.receiver.phone === "") {
        this.setErrorMessage(
          RECEIVER,
          "phone number is required for international shipments",
        );
        valid = false;
      }
      if (!this.props.sender.phone) {
        this.setErrorMessage(
          SENDER,
          "phone number is required when sending to international shipments",
        );
        valid = false;
      } else if (this.props.sender.phone === "") {
        this.setErrorMessage(
          SENDER,
          "phone number is required when sending to international shipments",
        );
        valid = false;
      }
      if (!this.props.sender.name.includes(" ")) {
        this.setErrorMessage(
          SENDER,
          "both first and last name is required when sending to international shipments",
        );
        valid = false;
      }

      if (!this.props.receiver.name.includes(" ")) {
        this.setErrorMessage(
          RECEIVER,
          "both first and last name is required when sending to international shipments",
        );
        valid = false;
      }
      const customsValidation = this.validateCustoms();
      valid = valid && customsValidation.valid;
      this.setState({
        customsError: customsValidation,
      });
    }
    return valid;
  };

  componentWillUnmount() {
    this.props.clearRate();
    this.props.clearRates();
    this.props.clearLabelCreationErrors();
    if (this.props.selectedOrder) {
      this.props.selectOrder(null);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.sender?.country !== this.props.receiver?.country) {
      this.setState({ showCustoms: true });
    } else {
      this.setState({ showCustoms: false });
    }
    if (
      prevProps.sender !== this.props.sender ||
      prevProps.receiver !== this.props.receiver ||
      prevProps.parcels !== this.props.parcels ||
      prevProps.customs !== this.props.customs
    ) {
      this.props.clearRate();
      this.props.clearRates();
      this.props.clearLabelCreationErrors();

      if (this.state.showRates) {
        this.toggleRates();
      }

      const customsValidation =
        this.state.showCustomsForm && !this.state.customsError.valid
          ? this.validateCustoms()
          : { valid: true };

      this.setState({
        receiverError: "",
        senderError: "",
        fieldsError: { valid: true },
        customsError: customsValidation,
      });

      if (
        prevProps.sender !== this.props.sender &&
        this.props.sender?.id &&
        !this.props.customs.items.every((item) => item.origin_country)
      ) {
        this.props.customs.items.forEach((item, idx) => {
          if (!item.origin_country) {
            this.props.editCustomsItems(
              {
                ...item,
                origin_country:
                  this.props.sender?.country ||
                  this.props.currentCountry ||
                  "US",
              },
              idx,
            );
          }
        });
      }

      if (
        prevProps.labelTemplate.selectedTemplate &&
        this.props.labelTemplate.selectedTemplate
      ) {
        console.log("updates made");
      }
    }
    if (
      this.state.showSaveTemplatePrompt &&
      prevProps.labelTemplate.selectedTemplate !==
        this.props.labelTemplate.selectedTemplate &&
      this.props.labelTemplate.selectedTemplate
    ) {
      this.setState({ showSaveTemplatePrompt: false });
      this.getRates();
    }

    if (
      prevProps.addressPageSize !== this.props.addressPageSize ||
      prevProps.addressSearchText !== this.props.addressSearchText
    ) {
      this.props.getAddresses(
        this.props.addressPageSize,
        this.props.addressSearchText,
      );
    }
    if (prevState !== this.state) {
      if (this.creationAddressList.current) {
        this.creationAddressList.current
          .getElementsByTagName("input")[0]
          .focus();
      }
    }

    if (
      prevProps.rateError !== this.props.rateError &&
      this.props.rateError &&
      Object.keys(this.props.rateError).length > 0
    ) {
      const propErrors = { ...this.props.rateError };

      if (propErrors.customs_declaration) {
        this.setState({
          customsError: {
            ...this.state.customsError,
            valid: false,
            items: Object.keys(propErrors.customs_declaration).includes(
              "items",
            ),
            signer: Object.keys(propErrors.customs_declaration).includes(
              "certify_signer",
            ),
            certified: Object.keys(propErrors.customs_declaration).includes(
              "certified",
            ),
            contents_explanation: Object.keys(
              propErrors.customs_declaration,
            ).includes("contents_explanation"),
          },
        });
      }
      if (propErrors.parcels) {
        this.setState({
          parcelsError: propErrors.parcels,
        });
      }
      if (propErrors.address_to) {
        this.setState({ receiverError: parseError(propErrors.address_to) });
      }
      if (propErrors.address_from) {
        this.setState({ senderError: parseError(propErrors.address_from) });
      }
    }
  };

  toggleCustoms = () => {
    this.setState({ showCustomsForm: !this.state.showCustomsForm });
  };

  toggleRates = () => {
    this.setState({ showRates: !this.state.showRates });
  };
  togglePurchase = () => {
    this.setState({ showPurchase: !this.state.showPurchase });
  };

  setErrorMessage(addressType, error) {
    this.setState({ [`${addressType}Error`]: `${error}` });
  }

  toggleList = (type) => {
    if (!this.state.showList) {
      this.props.getAddresses(this.props.addressPageSize);
    } else {
      this.props.searchAddresses("");
    }
    this.setState({
      showList: !this.state.showList,
      listType: type,
    });
  };
  toggleForm = (type) => {
    this.setState({
      showForm: !this.state.showForm,
      formType: type,
    });
  };

  toggleTemplate = () => {
    this.setState({
      createTemplate: !this.state.createTemplate,
      templateError: "",
    });

    this.props.clearNewTemplate();
  };

  toggleTemplateList = () => {
    this.setState({
      showTemplateList: !this.state.showTemplateList,
    });
  };

  toggleAddressConfirmation = (id) => {
    this.setState({
      showAddressDeleteConfirmation: id,
    });
  };

  checkShowCarriers = () => {
    return (
      this.props.sender?.country === this.props.receiver?.country ||
      (this.props.sender?.country !== this.props.receiver?.country &&
        this.props.showInternational)
    );
  };

  validateFromUS(address) {
    return address.country === "US" || address.country === "USA";
  }

  validateAddress(address, addressType) {
    if (!address || !address.id || address.id === "") {
      this.setErrorMessage(
        addressType,
        "This address is not complete. Finish creating the address by clicking edit and click save when finished",
      );
      return false;
    }
    if (!!address.zip_code && address.zip_code.length > 12) {
      this.setErrorMessage(
        addressType,
        "Address postal/zip code length exceeds 12 characters.",
      );
      return false;
    }
    return true;
  }

  getRates = async (overrideTemplatePrompt = false) => {
    const to = this.props.receiver;
    const from = this.props.sender;
    this.setState({
      receiverError: "",
      senderError: "",
      fieldsError: { valid: true },
      customsError: { valid: true },
      parcelsError: [],
    });
    this.props.clearLabelCreationErrors();

    if (await this.validateRateChecks()) {
      const requestParams = {
        address_to: to,
        address_from: from,
        parcels:
          this.props.parcels?.parcels.length > 0
            ? [...this.props.parcels.parcels]
            : [{}],
        extras: this.props.selectedExtras,
      };

      if (this.props.sender.country !== this.props.receiver.country) {
        const {
          certified,
          contentType,
          contentsExplanation,
          customsSigner,
          nonDeliveryOption,
          items,
          incoterm,
          eel_pfc,
        } = this.props.customs;
        const validatedItems = items.map((item) => {
          const validatedItem = { ...item };
          if (
            Object.keys(validatedItem).includes("tariff_number") &&
            (!validatedItem.tariff_number || isNaN(validatedItem.tariff_number))
          ) {
            delete validatedItem.tariff_number;
          }
          return validatedItem;
        });
        const declaration = {
          contents_type: contentType,
          non_delivery_option: nonDeliveryOption,
          certify: certified,
          certify_signer: customsSigner,
          contents_explanation: contentsExplanation,
          incoterm,
          eel_pfc,
          items: validatedItems,
        };
        requestParams["declaration"] = declaration;
      }

      if (
        !this.props.labelTemplate.selectedTemplate &&
        !overrideTemplatePrompt &&
        this.state.askSaveTemplate
      ) {
        this.setState({ showSaveTemplatePrompt: true });
        return;
      }
      this.props.getRates(requestParams, () =>
        this.setState({ showRates: true }),
      );
    } else {
      document.getElementsByClassName("home-content")[0].scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  renderSaveTemplate = () => {
    return (
      <div className="modal">
        <div
          className="backdrop"
          onClick={() => this.setState({ showSaveTemplatePrompt: false })}
        ></div>
        <div className="modal-content save-template-prompt d-flex flex-column align-items-center text-center">
          <div className={"prompt-icon"}>
            <TemplateListIcon />
          </div>
          <div className={"col-12 col-lg-9"}>
            <p>
              The contents of the form have not been saved. Do you want to
              create a template to reuse the contents?
            </p>
          </div>
          <div className="col-12 col-lg-9 p-0 d-flex flex-column justify-content-between">
            <button
              onClick={() => {
                this.toggleTemplate();
              }}
              className="delete-button"
            >
              Create Template
            </button>
            <button
              onClick={() => {
                this.setState({
                  showSaveTemplatePrompt: false,
                  askSaveTemplate: false,
                });
                this.getRates(true);
              }}
              className={"no-bg"}
            >
              Don't Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderVerificationPrompt = () => {
    const { pathname } = window.location;

    return (
      <div className="modal">
        <div
          className="backdrop"
          onClick={() => {
            this.setState({ showVerificationPrompt: false });
          }}
          onKeyDown={() => {
            this.setState({ showVerificationPrompt: false });
          }}
        ></div>
        <div className="modal-content verify-details-prompt d-flex flex-column align-items-center text-center">
          <div className={"prompt-icon"}>
            <FiAlertCircle />
          </div>
          <div className={"col-12 col-lg-9"}>
            <p>{`
            ${
              missingDetails(this.props.businessDetails)
                ? "Verify your business to send international packages. Fill in the form and our team will be in touch immediately."
                : "Your business details are being verified. Our team will be in touch immediately."
            }
          `}</p>
          </div>
          <div className="col-12 col-lg-9 p-0 d-flex flex-column justify-content-between">
            <button
              onClick={() => {
                this.props.setApplicationContext(
                  { returnURL: pathname, actionType: "edit" },
                  () => {
                    history.push(ROUTES.BANK_ACCOUNT_DETAILS);
                  },
                );
              }}
              className="delete-button"
            >
              Go to Business Details
            </button>
            <button
              onClick={() => {
                this.setState({
                  showVerificationPrompt: false,
                });
              }}
              className={"no-bg"}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderError = () => {
    if (this.state.senderError !== "") {
      return `Sender Address Error: ${this.state.senderError}`;
    } else if (this.state.receiverError !== "") {
      return `Receiver Address Error: ${this.state.receiverError}`;
    } else if (!this.state.fieldsError.valid) {
      return `Package Error: ${this.state.fieldsError.message}`;
    } else if (!this.state.customsError.valid) {
      return `Customs Error: ${parseError(this.props.customs?.errors) || "Please make sure you filled out the customs declaration properly"}`;
    } else return null;
  };

  renderNextButton = () => {
    const error = this.renderError();
    return (
      <div>
        {error && <MessageBlock type={"error"} message={error} />}
        <div className="col-12 p-0 d-flex flex-row justify-content-between">
          <button className="col-12 p-0" onClick={() => this.getRates()}>
            {this.props.loading || this.props.customs.loading ? (
              <div className="spinner-border text-light"></div>
            ) : (
              "Get Rates"
            )}
          </button>
        </div>
      </div>
    );
  };

  renderListRow = (item) => {
    const {
      id,
      name,
      company,
      street1,
      street2,
      city,
      state,
      zip_code,
      country,
      email,
      phone,
      is_default,
    } = item;
    return (
      <div
        key={id}
        className={
          "address-list-item-container d-flex flex-row justify-content-between align-items-center"
        }
        onClick={() => {
          this.props.selectAddress(item, this.state.listType, () =>
            this.toggleList(this.state.listType),
          );
        }}
      >
        <div className="address-list-item d-flex flex-column" key={id}>
          <h3>
            {`${name} `}
            {company ? `@ ${company}` : null}{" "}
            {is_default ? <span className="default-tag">DEFAULT</span> : null}
          </h3>
          {/* <hr className="m-0 p-1"/> */}
          <p className="address">
            {`${street1}, ${street2 || ""}, ${city}, ${state} ${zip_code}, ${country}`}{" "}
          </p>
          {email && <p>{email}</p>}
          {phone && <p>{phone}</p>}
        </div>
        <div className={"address-list-item-actions"}>
          {!is_default && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                this.props.setDefaultAddress(item, () =>
                  this.props.getAddresses(
                    this.props.addressPageSize,
                    this.props.addressSearchText,
                  ),
                );
              }}
              aria-disabled={this.props.addressDefaultLoading}
            >
              {id === this.props.addressDefaultLoading ? (
                <Spinner className={"text-dark"} />
              ) : (
                "Set Default"
              )}
            </a>
          )}
          {this.state.showAddressDeleteConfirmation === id ? (
            <div className={"delete-address-confirmations"}>
              <a
                className={"confirm"}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.deleteAddress(id, () => {
                    this.props.getAddresses(this.props.addressPageSize);
                    this.toggleAddressConfirmation(null);
                  });
                }}
              >
                <FiCheck />
              </a>
              <a
                className={"deny"}
                onClick={(e) => {
                  e.stopPropagation();
                  this.toggleAddressConfirmation(null);
                }}
              >
                <FiX />
              </a>
            </div>
          ) : (
            <a
              className={"delete-address-item"}
              onClick={(e) => {
                e.stopPropagation();
                this.toggleAddressConfirmation(id);
              }}
            >
              <FiTrash2 />
            </a>
          )}
        </div>
      </div>
    );
  };
  renderList = () => {
    let list = null;
    if (this.props.getAddressLoad) {
      list = <div className="spinner-border text-dark m-auto"></div>;
    } else if (this.props.addressErrors) {
      list = <p className="mb-0 text-danger">{this.props.addressErrors}</p>;
    } else if (this.state.showList) {
      list = this.props.addressList.map((item) => this.renderListRow(item));
    }
    return (
      this.state.showList && (
        <div
          ref={this.creationAddressList}
          className="modal col-12 p-0 d-flex justify-content-center justify-content-lg-start aligin-items-center"
        >
          <div
            className="backdrop"
            onClick={() => this.toggleList(this.state.listType)}
          ></div>

          <div className="modal-content address-list settings-card d-flex col-11 col-lg-7 m-auto">
            <div className="list-head card-section head d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <h3>My Saved Addresses</h3>
                <p
                  className="refresh-btn mb-0 mx-3"
                  onClick={() =>
                    this.props.getAddresses(this.props.addressPageSize)
                  }
                >
                  Refresh
                </p>
              </div>
              <a onClick={() => this.toggleList(this.state.listType)}>
                <FiX size={25} />
              </a>
            </div>
            <div className="list-content card-section last">
              <div className="search-action">
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    this.props.searchAddresses(e.target.value);
                  }}
                  value={this.props.addressSearchText}
                />
                <FiSearch size={25} className="search-icon" />
                <FiX
                  size={25}
                  className={`clear-icon ${this.props.addressSearchText.toString().trim() !== "" ? "active" : ""}`}
                  onClick={() => {
                    this.props.searchAddresses("");
                  }}
                />
              </div>
              {list}
            </div>
            <div className={"list-content card-section last"}>
              <Pagination
                current={this.props.addressCurrent}
                count={this.props.addressCount}
                pageSize={this.props.addressPageSize}
                getListByPage={(pageNo) =>
                  this.props.getAddressesByPage(
                    null,
                    pageNo,
                    this.props.addressPageSize,
                    this.props.searchText,
                  )
                }
                setPageSize={(size) => this.props.setAddressPageSize(size)}
              />
            </div>
          </div>
        </div>
      )
    );
  };

  renderSender = () => {
    return (
      <div
        className={`label-create-address sender ${this.state.senderError ? "show-error" : ""}`}
      >
        <div className="address-header col-12 p-0 d-flex justify-content-between  flex-column flex-md-row">
          <h3 className="col-12 col-md-4 p-0">Sender</h3>
          <div className="address-actions p-0 d-flex flex-row justify-content-end align-items-center mb-3">
            <a onClick={() => this.toggleList(SENDER)}>Add From List</a>
            <p>or</p>
            <a
              onClick={() => {
                this.props.clearSenderAddress();
                this.toggleForm(SENDER);
              }}
            >
              Add New Sender
            </a>
          </div>
        </div>
        {this.state.senderError && (
          <div className={"error-block"}>
            <FiAlertCircle />
            <p>{this.state.senderError}</p>
          </div>
        )}
        {this.props.sender && !!this.props.sender.id && (
          <AddressListItem
            type={SENDER}
            item={this.props.sender}
            onFinish={() => this.toggleForm(SENDER)}
            toggleForm={() => this.toggleForm(SENDER)}
            toggleAddrList={() => this.toggleList(SENDER)}
            errors={this.state.senderError}
          />
        )}
      </div>
    );
  };
  renderReceiver = () => {
    return (
      <div
        className={`label-create-address receiver ${this.state.receiverError ? "show-error" : ""}`}
      >
        <div className="address-header col-12 p-0 d-flex justify-content-between flex-column flex-md-row">
          <h3 className="col-12 col-md-4 p-0">Receiver</h3>
          <div className="address-actions p-0 d-flex flex-row justify-content-end align-items-center mb-3">
            <a onClick={() => this.toggleList(RECEIVER)}>Add From List</a>
            <p>or</p>
            <a
              onClick={() => {
                this.props.clearReceiverAddress();
                this.toggleForm(RECEIVER);
              }}
            >
              Add New Receiver
            </a>
          </div>
        </div>
        {this.state.receiverError && (
          <div className={"error-block"}>
            <FiAlertCircle />
            <p>{this.state.receiverError}</p>
          </div>
        )}
        {this.props.receiver && !!this.props.receiver.id && (
          <AddressListItem
            type={RECEIVER}
            item={this.props.receiver}
            onFinish={() => this.toggleForm(RECEIVER)}
            toggleForm={() => this.toggleForm(RECEIVER)}
            toggleAddrList={() => this.toggleList(RECEIVER)}
            errors={this.state.receiverError}
          />
        )}
      </div>
    );
  };

  renderCreateForm = () => {
    const {
      sender,
      receiver,
      rates,
      declaration,
      selectedParcel,
      selectedWeight,
      packageType,
    } = this.props;

    return (
      <div className="settings-card label-create col-12 col-lg-6 mx-2 p-0 d-flex flex-column">
        <div className="card-section head d-flex flex-row align-items-center">
          <h3 className="create-title">Create Shipping Label</h3>
        </div>

        <div className="card-section address-selection top ">
          {this.renderSender()}
        </div>

        <div className="card-section address-selection">
          {this.renderReceiver()}
        </div>
        <div className="card-section">
          <PackageDetails
            className="card-section"
            errors={this.state.fieldsError}
            parcelsError={this.state.parcelsError}
            toggleTemplateForm={() => this.toggleTemplate()}
            toggleTemplateList={() => this.toggleTemplateList()}
          />
        </div>

        <CustomsInfo
          show={this.state.showCustoms}
          toggleForm={() => this.toggleCustoms()}
          errors={this.state.customsError}
        />

        <div className="card-section last">
          <div className="button-area d-flex flex-column">
            <label
              className={"form-checkbox "}
              onClick={() =>
                this.props.updateExtras(
                  "requireSignature",
                  !this.props.selectedExtras.requireSignature,
                )
              }
            >
              <div
                className={`check mr-2 ${this.props.selectedExtras.requireSignature ? "active" : ""}`}
              >
                <Check />
              </div>
              <p>Requires Signature</p>
            </label>
            {this.renderNextButton()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className={`labelMain col-12 p-0 m-auto d-flex flex-column flex-lg-row justify-content-lg-between ${this.props.cookies.get("is_test") ? "test-mode" : "live-mode"}`}
      >
        {this.renderCreateForm()}

        <RateSelection
          refresh={() => this.getRates()}
          toggleShow={() => this.toggleRates()}
          togglePurchase={() => this.togglePurchase()}
          showCarriers={this.checkShowCarriers()}
          paymentOnDeliveryAvailable={
            this.props.selectedOrder?.amount_to_be_collected > 0
          }
        />

        {this.state.showForm && (
          <AddressForm
            show={this.state.showForm}
            toggleForm={() => this.toggleForm(this.state.formType)}
            type={this.state.formType}
            onSuccess={() => {
              this.toggleForm(this.state.formType);
              this.props.getAddresses(
                this.props.addressPageSize,
                this.props.addressSearchText,
              );
            }}
          />
        )}
        {this.renderList()}
        {this.state.showCustomsForm && (
          <CustomsItemForm
            show={this.state.showCustomsForm}
            toggleForm={() => this.toggleCustoms()}
          />
        )}
        {this.props.selectedRate &&
          this.props.selectedRate.id &&
          this.state.showPurchase && (
            <PurchaseLabel
              toggleModal={() => this.togglePurchase()}
              showCarriers={this.checkShowCarriers()}
            />
          )}
        {this.state.createTemplate && (
          <LabelTemplate
            element={this.templateFormRef.current}
            toggleTemplate={() => this.toggleTemplate()}
            showCustoms={this.state.showCustoms}
            showCustomsForm={this.state.showCustomsForm}
            toggleCustomsForm={() => this.toggleCustoms()}
            showSaveTemplatePrompt={this.state.showSaveTemplatePrompt}
            packageDetailErrors={this.state.fieldsError}
            customsErrors={this.state.customsError}
            parcelsError={this.state.parcelsError}
          />
        )}
        {!this.state.showCustomsForm &&
          !this.state.createTemplate &&
          this.state.showTemplateList && (
            <TemplateList
              element={this.templateFormRef.current}
              toggleCreate={() => this.toggleTemplate()}
              toggleList={() => this.toggleTemplateList()}
              showCustoms={this.state.showCustoms}
            />
          )}
        {!this.state.createTemplate &&
          this.state.showSaveTemplatePrompt &&
          this.renderSaveTemplate()}
        {this.state.showVerificationPrompt && this.renderVerificationPrompt()}
      </div>
    );
  }
}

const mapStateToProps = ({
  senderAddress,
  receiverAddress,
  addressList,
  parcels,
  rateChoices,
  customs,
  labelTemplate,
  newTemplate,
  stores,
  applicationContext,
  businessDetails,
  userDetails,
}) => ({
  sender: senderAddress,
  receiver: receiverAddress,
  defaultAddress: userDetails.info?.default_address,
  addressList: addressList.addresses,
  getAddressLoad: addressList.loading,
  addressErrors: addressList.errors,
  addressSearchText: addressList.searchText,
  addressCurrent: addressList.currentPage,
  addressCount: addressList.addressResponse?.count,
  addressPageSize: addressList.pageSize,
  addressDefaultLoading: addressList.defaultToggleLoading,
  selectedExtras: parcels.selectedExtras,
  selectedParcel: parcels.selectedParcel,
  selectedWeight: parcels.selectedWeight,
  declaration: parcels.declaration,
  useTemplate: parcels.useTemplate,
  packageType: parcels.packageType,
  loading: rateChoices.loading,
  rateError: rateChoices.errors,
  rates: rateChoices.rates,
  selectedRate: rateChoices.selectedRate,
  parcels,
  customs,
  labelTemplate,
  newTemplate,
  selectedOrder: stores.selectedOrder,
  templatesIntro: labelTemplate.intro,
  currentCountry: applicationContext.context.country,
  businessDetails: businessDetails.details,
  applicationContext,
  showInternational: userDetails.info?.show_international_carriers,
});

export default compose(
  withRouter,
  withCookies,
  connect(mapStateToProps, {
    selectAddress,
    clearSenderAddress,
    clearReceiverAddress,
    updateExtras,
    getRates,
    getAddresses,
    selectRate,
    clearCustoms,
    clearRate,
    clearRates,
    updateNewTemplate,
    createTemplate,
    updateTemplateName,
    selectTemplate,
    clearNewTemplate,
    clearSelectedTemplate,
    createCustomsDeclaration,
    editCustomsItems,
    getUserCountry,
    selectOrder,
    searchAddresses,
    getAddressesByPage,
    setAddressPageSize,
    deleteAddress,
    setDefaultAddress,
    clearLabelCreationErrors,
    addParcelItem,
    setApplicationContext,
  }),
)(CreateLabel);
