import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FormField } from "../common/FormField";
import { connect } from "react-redux";
import {
  clearCartConfigErrors,
  clearSelectedCheckout,
  createCartConfiguration,
  createConfigUpdateForm,
  getCartConfigurations,
  selectCheckoutConfig,
} from "../../actions/CheckoutsActions";
import CURRENCYUNITS from "../../../assets/currency-units.json";
import { CHECKOUT, SENDER } from "../../../constants/types";
import AddressSelectItem from "../Address/AddressSelectItem";
import AddressForm from "../Address/AddressForm";
import {
  clearCheckoutAddress,
  getAddresses,
  getConnectedStores,
  searchAddresses,
  selectAddress,
  selectStore,
} from "../../actions";
import AddressListModal from "../Address/AddressListModal";
import { FiCheck, FiInfo } from "react-icons/fi";
import MessageBlock from "../common/MessageBlock";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import { parseError } from "../../actions/ActionHelpers";
import FileUpload from "../common/FileUpload";
import { clearApplicationContext } from "../../actions/ApplicationContextActions";
import ToolTip from "../common/ToolTip";
import MPESA from "../../../assets/mpesa-logo.svg";
import VISA from "../../../assets/visa_card.svg";

const CheckoutsCreate = (props) => {
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showAddrForm, setShowAddrForm] = useState(false);
  const [showAddrList, setShowAddrList] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    valid: true,
  });
  const [acceptPOD, setAcceptPOD] = useState(
    parseFloat(props.createData.amount_up_front_percentage) < 1,
  );
  const [buyerFOB, setBuyerFOB] = useState(true);

  useEffect(() => {
    if (!props.checkoutAddress?.id && !!props.defaultAddress?.id) {
      props.selectAddress(props.defaultAddress, CHECKOUT);
    }
  }, []);

  useEffect(() => {
    setValidated(false);
    setFieldErrors({ valid: true });
    props.clearCartConfigErrors();
  }, [props.checkoutAddress]);

  useEffect(() => {
    if (props.errors) {
      if (
        Object.keys(props.errors).some((item) =>
          Object.keys(props.createData).includes(item),
        )
      ) {
        const errors = {};
        Object.keys(props.errors).forEach((field) => {
          if (fieldErrors[field]) {
            errors[field] = fieldErrors[field];
          } else {
            errors[field] = parseError(props.errors[field]);
          }
        });
        setFieldErrors({
          valid: false,
          ...errors,
        });
      } else {
        setErrorMsg(parseError(props.errors));
      }
    } else {
      setErrorMsg("");
    }
  }, [props.errors]);

  const setter = (fieldName) => {
    return (value) => {
      setFieldErrors({
        valid: true,
      });
      setValidated(false);
      props.clearCartConfigErrors();
      props.createConfigUpdateForm(fieldName, value);
    };
  };

  const currencyElements = Object.keys(CURRENCYUNITS).map((item, idx) => {
    return (
      <option key={item} value={item}>
        {CURRENCYUNITS[item].description}
      </option>
    );
  });

  const handleFile = (files) => {
    if (!files?.length) {
      // setter("shop_logo")("");
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const dataUrl = e.target.result;
      setter("shop_logo")(dataUrl);
    };

    reader.readAsDataURL(files[0]);
  };

  return (
    <div className={"checkouts-create-form settings-card"}>
      <div className={"card-section head"}>
        <h3>Create Store</h3>
        <a
          className={"action red"}
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </a>
      </div>
      <div className={"card-section last"}>
        <Form
          noValidate
          validated={validated}
          onSubmit={(event) => {
            event.stopPropagation();
            event.preventDefault();
            const data = {
              ...props.createData,
              default_address_from: props.checkoutAddress,
            };
            const form = event.currentTarget;
            if (
              isNaN(props.createData.amount_up_front_percentage) ||
              !acceptPOD
            ) {
              data.amount_up_front_percentage = 1;
            }

            let isFormValid = form.checkValidity();
            setValidated(true);
            if (!data.default_address_from?.id) {
              setFieldErrors({
                ...fieldErrors,
                valid: false,
                default_address_from:
                  "Please select or create an address for the store",
              });
              isFormValid = false;
            }
            if (!data.shop_logo && !data.id) {
              setFieldErrors({
                ...fieldErrors,
                valid: false,
                shop_logo: "Please select a logo file",
              });

              isFormValid = false;
            }
            if (!!data.id && data.shop_logo.startsWith("http"))
              delete data.shop_logo;

            if (isFormValid) {
              props.createCartConfiguration(data).then((res) => {
                if (props.returnURL === ROUTES.CSV_IMPORT_PAGE) {
                  // * CSV Specific flow;
                  history.replace(props.returnURL + "?shop_id=" + res.shop);
                  props.clearApplicationContext();
                } else {
                  props.getCartConfigurations(() => {
                    props.selectCheckoutConfig(res, () => {
                      if (data.id)
                        history.replace(
                          `${ROUTES.CHECKOUTS_DETAILS}/${data.id}`,
                        );
                      else
                        history.replace(`${ROUTES.CHECKOUTS_PRODUCTS_CREATE}`);
                    });
                  });
                }
              });
            }
          }}
        >
          {!props.createData?.id && (
            <MessageBlock
              icon={<FiInfo />}
              message={
                "This creates a store to add products for checkout links"
              }
            />
          )}
          <FormField
            controlId={"checkout.name"}
            className={""}
            label={"Store Name"}
            isRequired
            value={props.createData.name_to_show_customers}
            type={"text"}
            setter={setter("name_to_show_customers")}
            placeholder={"Store Name"}
            errorMessage={props.errors?.name_to_show_customers}
          />
          <div
            className={
              "field-group d-flex flex-column flex-lg-row align-items-lg-center justify-content-between"
            }
          >
            <FormField
              controlId={"checkout.margin"}
              className={"col-9"}
              label={"Safety Margin (%)"}
              isRequired
              value={Math.round(
                (parseFloat(props.createData.margin_multiplier) - 1) * 100,
              )}
              type={"number"}
              setter={(value) =>
                setter("margin_multiplier")(parseFloat(value) / 100 + 1)
              }
              placeholder={"Margin Multiplier"}
              errorMessage={props.errors?.margin_multiplier}
              tooltip={
                "This field allows you to add a percentage markup on the shipping cost"
              }
              min={0}
              max={100}
            />
            <Form.Group controlId="checkout.currency" className={"col-3"}>
              <Form.Label className={"d-flex flex-row align-items-start"}>
                Currency
                <OverlayTrigger
                  key={"checkout-address-info"}
                  placement="auto"
                  overlay={
                    <Tooltip
                      id="checkout-address-info"
                      className={"checkout-address-info"}
                    >
                      {
                        "This is the currency used for customer payments and product prices"
                      }
                    </Tooltip>
                  }
                  delayShow={300}
                  delayHide={150}
                >
                  <span className={"checkout-address-info"}>
                    <FiInfo className={"warning"} />
                  </span>
                </OverlayTrigger>
                <p className={"text-danger m-0"}>*</p>
              </Form.Label>
              <Form.Select
                // disabled
                value={props.createData.currency || "KES"}
                onChange={(e) => {
                  setter("currency")(e.target.value);
                }}
              >
                {Object.keys(CURRENCYUNITS)?.length > 0 ? (
                  currencyElements
                ) : (
                  <option>Select your currency</option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a country
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <FormField
            controlId={"checkout.url"}
            className={""}
            label={"Store URL"}
            value={props.createData.url}
            type={"text"}
            setter={setter("url")}
            placeholder={"Store URL (optional)"}
            errorMessage={props.errors?.url}
            tooltip={
              "Website URL, this will otherwise be represented by the store name"
            }
          />

          <div>
            <Form.Group controlId="checkout.shop_logo" className={""}>
              <Form.Label className={"d-flex flex-row align-items-start"}>
                Store Logo <p className={"text-danger m-0"}>*</p>
              </Form.Label>
              <FileUpload
                fileType={"image/*"}
                onChange={(files) => {
                  handleFile(files);
                }}
                validate={(files) => {
                  return files.every((file) => {
                    if (parseFloat(file.size) / 1024 > 500) {
                      setFieldErrors({
                        valid: false,
                        shop_logo: "File size must not exceed 500KB",
                      });
                      return false;
                    }
                    return true;
                  });
                }}
                error={fieldErrors.shop_logo}
                files={
                  props.createData?.shop_logo
                    ? [{ preview: props.createData?.shop_logo }]
                    : []
                }
                filesRemove={(idx) => {
                  setter("shop_logo")("");
                }}
              />
              {!!fieldErrors.shop_logo && (
                <p className={"text-danger"}>
                  {fieldErrors.shop_logo || "Please select a logo file"}
                </p>
              )}
            </Form.Group>
            <p className={"info"}>Max file size 500KB</p>
            <p className={"info"}>Recommended dimensions 100px</p>
          </div>
          <div>
            <div
              className={`d-flex flex-column align-items-center justify-content-between ${fieldErrors.default_address_from ? "show-error" : ""}`}
            >
              {/*{!!fieldErrors.default_address_from  && <MessageBlock type={'error'} message={parseError(fieldErrors.default_address_from)} icon={<FiInfo/>}/>}*/}
              <AddressSelectItem
                title={"Store Address"}
                address={props.checkoutAddress}
                formType={CHECKOUT}
                toggleList={() => setShowAddrList(!showAddrList)}
                toggleForm={() => setShowAddrForm(!showAddrForm)}
                clearAddress={() => clearCheckoutAddress()}
                showForm={showAddrForm}
                error={fieldErrors.default_address_from}
                tooltip={
                  "This address defines where the shipment is sent from or sender address"
                }
              />
            </div>
          </div>
          <div className={`payments-section`}>
            <div className={"d-flex flex-row align-items-center"}>
              <h4>
                Payments
                <ToolTip
                  key={"checkout-payment-info"}
                  placement={"auto"}
                  message={
                    "This shows what payment methods are supported for the checkout and if Payment On Delivery is supported"
                  }
                  className={"payment-payment-info"}
                />
              </h4>
            </div>
            <div className={"accepted-payments d-flex flex-column"}>
              Supported Payment Options
              <ul className={"d-flex flex-row align-items-center"}>
                <li>
                  <img src={VISA} alt={"credit_card"} />
                  <p>Credit Card</p>
                </li>
                <li
                  className={`${props.createData.currency !== "KES" ? "disabled" : ""}`}
                >
                  <img src={MPESA} alt={"mpesa"} />
                  <p>MPESA</p>
                </li>
              </ul>
              {props.createData.currency !== "KES" && (
                <p className={"info"}>MPesa only available for KES currency</p>
              )}
            </div>
            {props.createData.currency === "KES" && (
              <div className={`mpesa-cod-section `}>
                <div
                  className={`checkbox mb-3 d-flex flex-row align-items-center justify-content-start ${acceptPOD ? "active" : ""}`}
                  onClick={() => setAcceptPOD(!acceptPOD)}
                >
                  <div className={`check`}>
                    <FiCheck />
                  </div>
                  <p>
                    <b>Payment on delivery options for MPESA</b>
                  </p>
                  <ToolTip
                    key={"checkout-payment-info"}
                    placement={"auto"}
                    message={
                      "What percentage of the subtotal is required on checkout, the rest will be collected on delivery.\n 100% means payment on delivery is not supported"
                    }
                    className={"payment-payment-info"}
                  />
                </div>
                <div>
                  <div className={`d-flex flex-row align-items-center mb-3`}>
                    <FormField
                      controlId={"checkout.amount_up_front_percentage"}
                      className={"percent-field mr-2"}
                      value={
                        props.createData.amount_up_front_percentage < 1
                          ? Math.round(
                              parseFloat(
                                props.createData.amount_up_front_percentage,
                              ) * 100,
                            )
                          : null
                      }
                      type={"number"}
                      isRequired
                      setter={(value) => {
                        setter("amount_up_front_percentage")(
                          parseFloat(value) / 100,
                        );
                      }}
                      placeholder={"100"}
                      errorMessage={props.errors?.amount_up_front_percentage}
                      tooltip={
                        "This field allows you to add a percentage markup on the shipping cost"
                      }
                      min={0}
                      max={100}
                      disabled={!acceptPOD}
                    />
                    <p className={"mb-0"}>
                      % of the total item cost paid at time of purchase
                    </p>
                  </div>
                  <div
                    className={`checkbox ${!acceptPOD ? "disabled" : ""} mb-3 d-flex flex-row align-items-center justify-content-start ${props.createData.buyer_pays_delivery_fee ? "active" : ""}`}
                    onClick={() => {
                      if (acceptPOD) {
                        setter("buyer_pays_delivery_fee")(
                          !props.createData.buyer_pays_delivery_fee,
                        );
                      }
                    }}
                  >
                    <div className={`check`}>
                      <FiCheck />
                    </div>
                    <p>Buyer pays delivery fee at time of purchase</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*<div className={`shipping-config-section`}>*/}
          {/*  <div className={""}>*/}
          {/*    <h4>Shipping Fee Configurations</h4>*/}
          {/*    <p className={"info"}>*/}
          {/*      Choose who will cover the shipping fees for orders*/}
          {/*    </p>*/}
          {/*    <div className={"shipping-fee-radio d-flex flex-column g-3"}>*/}
          {/*      <div*/}
          {/*        className={`radio-item d-flex flex-row justify-content-start ${buyerFOB ? "active" : ""}`}*/}
          {/*        onClick={() => setBuyerFOB(true)}*/}
          {/*      >*/}
          {/*        <div className={`check`}>*/}
          {/*          <div></div>*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*          <p>Buyer pays shipping fees</p>*/}
          {/*          {acceptPOD && !props.createData.buyer_pays_delivery_fee && (*/}
          {/*            <p className={"info"}>*/}
          {/*              The merchant will first cover the shipping cost, which*/}
          {/*              will then be charged to the buyer at checkout or on*/}
          {/*              delivery. If the buyer refuses to pay or the item cannot*/}
          {/*              be delivered, the merchant will be responsible for the*/}
          {/*              shipping fees.*/}
          {/*            </p>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div*/}
          {/*        className={`radio-item d-flex flex-row justify-content-start ${!buyerFOB ? "active" : ""}`}*/}
          {/*        onClick={() => setBuyerFOB(false)}*/}
          {/*      >*/}
          {/*        <div className={`check`}>*/}
          {/*          <div></div>*/}
          {/*        </div>*/}
          {/*        <p>Merchant pays shipping fees</p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {errorMsg && (
            <MessageBlock
              icon={<FiInfo />}
              type={"error"}
              message={parseError(errorMsg)}
            />
          )}
          <button type={"submit"} disabled={props.loading}>
            {props.loading ? <Spinner animation={"border"} /> : "Save"}
          </button>
        </Form>
      </div>
      {showAddrForm && (
        <AddressForm
          show={showAddrForm}
          toggleForm={() => {
            setShowAddrForm(false);
          }}
          type={CHECKOUT}
          onSuccess={() => {
            props.createConfigUpdateForm(
              "default_address_from",
              props.checkoutAddress,
              () => {
                setShowAddrForm(false);
                props.getAddresses();
              },
            );
          }}
        />
      )}
      {showAddrList && (
        <AddressListModal
          toggleList={() => {
            setShowAddrList(!showAddrList);
            if (showAddrList) {
              props.searchAddresses("");
            }
          }}
          showList={showAddrList}
          updateSelectedAddress={(item) => {
            props.createConfigUpdateForm("default_address_from", item);
          }}
          listType={CHECKOUT}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  checkouts,
  checkoutAddress,
  addressList,
  applicationContext,
  csvImport,
  userDetails,
}) => ({
  errors: checkouts.errors,
  loading: checkouts.loading,
  createData: checkouts.selectedConfig,
  checkoutAddress,
  defaultAddress: userDetails.info?.default_address,
  returnURL: applicationContext.context.returnURL,
});

export default connect(mapStateToProps, {
  createConfigUpdateForm,
  getAddresses,
  clearCheckoutAddress,
  selectAddress,
  searchAddresses,
  createCartConfiguration,
  clearCartConfigErrors,
  getCartConfigurations,
  clearSelectedCheckout,
  selectCheckoutConfig,
  clearApplicationContext,
})(CheckoutsCreate);
