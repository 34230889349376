import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CHECKOUT,
  PHONEBOOK,
  RECEIVER,
  SENDER,
} from "../../../constants/types";
import {
  selectAddress,
  deleteAddress,
  clearSenderAddress,
  clearReceiverAddress,
  setDefaultAddress,
  clearCheckoutAddress,
  getAddresses,
} from "../../actions";
import * as ROUTES from "../../../constants/routes";
import { FiEdit2, FiMail, FiPhone, FiTrash2, FiX } from "react-icons/fi";
import { Spinner } from "react-bootstrap";

class AddressListItem extends Component {
  deleteAddress = () => {
    this.props.selectAddress(this.props.item, this.props.type, () =>
      this.props.toggleOverlay(true),
    );
  };

  render() {
    const type = this.props.type;
    if (this.props.item || this.props.item.name !== "") {
      const {
        id,
        name,
        company,
        phone,
        email,
        street1,
        street2,
        city,
        state,
        zip_code,
        country,
        is_default,
      } = this.props.item;
      return (
        <div
          key={this.props.item.id}
          className="address-item card-section col-12 d-flex flex-column justify-content-between"
        >
          <div className="address-info col-12 p-0 d-flex flex-column justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center mb-4">
              <h3 className="">
                {`${name} `}
                {company ? `@ ${company}` : null}{" "}
                {is_default ? (
                  <span className="default-tag">DEFAULT</span>
                ) : null}
              </h3>
              {!this.props.noEdit && (
                <div className="d-flex justify-content-end align-items-center">
                  {type === PHONEBOOK && !is_default && (
                    <a
                      onClick={() =>
                        this.props.setDefaultAddress(this.props.item, () =>
                          this.props.getAddresses(
                            this.props.addressPageSize,
                            this.props.addressSearchText,
                          ),
                        )
                      }
                      aria-disabled={this.props.addressDefaultLoading}
                    >
                      {id === this.props.addressDefaultLoading ? (
                        <Spinner className={"text-dark"} />
                      ) : (
                        "Set Default"
                      )}
                    </a>
                  )}
                  <a
                    onClick={() => {
                      this.props.selectAddress(
                        this.props.item,
                        type,
                        this.props.onFinish,
                      );
                    }}
                  >
                    <FiEdit2 size={20} />
                  </a>
                  {type === PHONEBOOK ? (
                    <a role={"button"} onClick={() => this.deleteAddress()}>
                      <FiTrash2 size={20} className={"stroke-danger"} />
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        if (type === SENDER) {
                          this.props.clearSenderAddress();
                        } else if (type === RECEIVER) {
                          this.props.clearReceiverAddress();
                        } else if (type === CHECKOUT) {
                          this.props.clearCheckoutAddress();
                        }
                      }}
                    >
                      <FiX size={20} />
                    </a>
                  )}
                </div>
              )}
            </div>
            {/* <hr className="m-0 p-1"/> */}
            <p className="address mb-3">
              {`${street1},`}
              {street2 ? ` #${street2},` : null}
              {` ${city}, ${state} ${zip_code}, ${country}`}{" "}
            </p>
            <div className="email-phone col-12 col-xl-7 d-flex p-0 flex-column flex-lg-row justify-content-between flex-wrap">
              {phone ? (
                <p className="bold d-flex flex-row align-items-center">
                  <FiPhone size={20} className="mr-3" />
                  {phone}
                </p>
              ) : null}
              {email ? (
                <p className="bold d-flex flex-row align-items-center">
                  <FiMail size={20} className="mr-3" />
                  {email}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ addressList, userDetails }) => ({
  defaultAddress: userDetails.info?.default_address,
  addressSearchText: addressList.searchText,
  addressPageSize: addressList.pageSize,
  addressDefaultLoading: addressList.defaultToggleLoading,
});

export default connect(mapStateToProps, {
  selectAddress,
  deleteAddress,
  clearSenderAddress,
  clearReceiverAddress,
  clearCheckoutAddress,
  setDefaultAddress,
  getAddresses,
})(AddressListItem);
