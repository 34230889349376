import {
  ADD_CARD,
  ADD_CARD_FAILURE,
  ADD_CARD_SUCCESS,
  CARD_LIST,
  CARD_PAY,
  DELETE_ADDRESS_SUCCESS,
  DELETE_CARD,
  DELETE_CARD_FAILURE,
  DELETE_CARD_SUCCESS,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_FAILURE,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  LOGOUT_SUCCESS,
  SAVE_ACCOUNT_INFO,
  SAVE_ACCOUNT_INFO_FAILURE,
  SAVE_ACCOUNT_INFO_SUCCESS,
  SET_DEFAULT_ADDRESS_SUCCESS,
  SET_DEFAULT_CARD,
  SET_DEFAULT_CARD_FAILED,
  SET_DEFAULT_CARD_SUCCESS,
  UPDATE_ACCOUNT_FORM_FIELD,
} from "../../constants/types";

const INITIAL_USER_STATE = {
  loading: false,
  errors: "",
  cards: [],
  referrals: [],
  info: {},
  defaultCard: null,
  selectedCard: null,
  paymentCard: null,
  defaultLoading: false,
};
export function userReducer(state = INITIAL_USER_STATE, action) {
  const list = [...state.cards];
  switch (action.type) {
    case GET_USER_DETAILS:
      return { ...state, loading: true, errors: "" };
    case GET_USER_DETAILS_SUCCESS:
      const isDefault = action.payload.credit_cards.find(
        (item) => item.is_default === true,
      );
      if (isDefault)
        return {
          ...state,
          cards: action.payload.credit_cards,
          defaultCard: isDefault,
          referrals: action.payload.referrals,
          loading: false,
          errors: "",
        };

      return {
        ...state,
        cards: action.payload.credit_cards,
        referrals: action.payload.referrals,
        loading: false,
        errors: "",
      };
    case GET_USER_DETAILS_FAILURE:
      return { ...state, loading: false, errors: action.payload.errors };
    case DELETE_CARD:
      return { ...state, loading: true };
    case DELETE_CARD_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case DELETE_CARD_SUCCESS:
      let temp = state.cards;
      let defaultCard = { ...state.defaultCard };
      let paymentCard = { ...state.paymentCard };
      let selectedCard = { ...state.selectedCard };
      temp.forEach((card, index) => {
        if (card.id === action.payload) {
          temp.splice(index, 1);
        }
      });
      if (action.payload === defaultCard.id) {
        defaultCard = null;
      }
      if (action.payload === paymentCard.id) {
        paymentCard = null;
      }
      return {
        ...state,
        loading: false,
        cards: temp,
        defaultCard: defaultCard,
        paymentCard: paymentCard,
        selectedCard: selectedCard,
        errors: "",
      };
    case ADD_CARD:
      return { ...state, loading: true, errors: "" };
    case ADD_CARD_SUCCESS:
      return { ...state, loading: false, errors: "" };
    case ADD_CARD_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case CARD_LIST:
      return {
        ...state,
        selectedCard: action.payload,
        loading: false,
        errors: "",
      };
    case CARD_PAY:
      return {
        ...state,
        paymentCard: action.payload,
        defaultCard: action.payload,
        loading: false,
        errors: "",
      };
    case SET_DEFAULT_CARD:
      return {
        ...state,
        defaultLoading: true,
        errors: "",
        selectedCard: action.payload,
      };
    case SET_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        defaultLoading: false,
        errors: "",
        defaultCard: action.payload,
        paymentCard: action.payload,
        selectedCard: action.payload,
      };
    case SET_DEFAULT_CARD_FAILED:
      return { ...state, defaultLoading: false, errors: action.payload };
    case SAVE_ACCOUNT_INFO_SUCCESS:
      return { ...state, info: action.payload, loading: false };
    case GET_ACCOUNT_INFO:
      return { ...state, loading: true, errors: "" };
    case GET_ACCOUNT_INFO_SUCCESS:
      return { ...state, loading: false, errors: "", info: action.payload };
    case GET_ACCOUNT_INFO_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case SET_DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        info: { ...state.info, default_address: action.payload },
      };
    case DELETE_ADDRESS_SUCCESS:
      return { ...state, info: { ...state.info, default_address: null } };
    case LOGOUT_SUCCESS:
      return INITIAL_USER_STATE;
    default:
      return state;
  }
}

const INITIAL_ACCOUNT_FORM = {
  full_name: "",
  business_name: "",
  business_website: "",
  phone_number: "",
  monthly_avg_shipment: "1-50",
  loading: false,
  errors: "",
};

export const accountFormReducer = (state = INITIAL_ACCOUNT_FORM, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT_FORM_FIELD:
      return { ...state, errors: "", ...action.payload };
    case SAVE_ACCOUNT_INFO:
      return { ...state, loading: true, errors: "" };
    case SAVE_ACCOUNT_INFO_FAILURE:
      return { ...state, loading: false, errors: action.payload };
    case SAVE_ACCOUNT_INFO_SUCCESS:
    case LOGOUT_SUCCESS:
      return INITIAL_ACCOUNT_FORM;
    default:
      return state;
  }
};
